/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import App, { type AppContext, type AppProps } from 'next/app'
import { logVitals } from '@/components/Sitewide/webVitals'

// These were ambiguous-build-order global imports I am consolidating here
import '@csc/dls/dist/index.css'
// base normalization of HTML and BODY nodes
import '@/components/Checkout/normalize.css' // RISKY ONE...
import '@/styles/extend.scss'
// These are global SCSS, just like the CSS ones above...
import '@/styles/header.scss'
import '@/styles/tailwind.scss'

// These are local files, with global imports - I am taking
//   a shot at resolving them to parent...
import '@/components/Pdp/AddProductToCartForm/style.scss'
import '@/components/Pdp/AccordionProductDetails/style.scss'

import dynamic from 'next/dynamic'
import Script from 'next/script'
import {
  isFeatureEnabled, appSSRFeatureTogglesSpy, popRequestId, pushRequestId,
} from '@/helpers/isFeatureEnabled'
import { PUBLIC_DNS_CDN_NAME, SHOP_ORIGIN } from '@/services/Configuration'
import { useRedirectIfNotOnShopDomain } from '@/hooks/useIsClientOnTheWrongDomain'

const RecoilRoot = dynamic(import('recoil').then((recoil) => recoil.RecoilRoot))
const CSChat = dynamic(import('@/components/ScriptIntegrations/CSChat'))
const CitizensPay = dynamic(import('@/components/ScriptIntegrations/CitizensPay'))
const Head = dynamic(import('next/head'))
const Listrak = dynamic(import('@/components/ScriptIntegrations/Listrak'))
const FacebookScript = dynamic(import('@/components/ScriptIntegrations/FacebookScript'))
const GoogleTagManager = dynamic(import('@/components/ScriptIntegrations/GoogleTagManager'))
const GoogleMapsScript = dynamic(import('@/components/ScriptIntegrations/GoogleMapsScript'))
const GoogleOptimizeScript = dynamic(import('@/components/ScriptIntegrations/GoogleOptimizeScript'))
const UseRedirectLegacyIoSAppsWatchdog = dynamic(import('@/components/Sitewide/UseRedirectLegacyIoSAppsWatchdog'))
const ExtendWarranty = dynamic(import('@/components/ScriptIntegrations/ExtendWarranty'))
const WunderKind = dynamic(import('@/components/ScriptIntegrations/WunderKind'))
const ApplePayScript = dynamic(import('@/components/ScriptIntegrations/ApplePayScript'))
const VWOScript = dynamic(import('@/components/ScriptIntegrations/VWOScript'))
const Connexity = dynamic(import('@/components/ScriptIntegrations/ConnexityScript'))
const ShopTokenScript = dynamic(import('@/components/ScriptIntegrations/ShopTokenScript'))
const AddShoppersScript = dynamic(import('@/components/ScriptIntegrations/AddShoppers'))

type CustomAppProps = {
  returnAPIOnly: boolean,
}

const shouldReturnAPIOnly = (props: unknown): boolean => (
  typeof props === 'object' && props !== null
  && Object.entries(props).find(([key, value]) => key === 'returnAPIOnly' && value === true)?.[1] === true
)
const MyApp = ({ Component, pageProps, returnAPIOnly }: AppProps & CustomAppProps) => {
  useRedirectIfNotOnShopDomain()
  useEffect(() => {
    logVitals()
  }, [])
  if (!!returnAPIOnly || shouldReturnAPIOnly(pageProps)) {
    return <Component {...pageProps} />
  }

  return (
    <RecoilRoot>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Head>
      <AddShoppersScript />
      <CitizensPay />
      <GoogleOptimizeScript />
      <ApplePayScript />
      <GoogleMapsScript />
      <FacebookScript />
      <GoogleTagManager />
      <UseRedirectLegacyIoSAppsWatchdog />
      <CSChat />
      <WunderKind />
      <VWOScript />
      <Script strategy="worker" src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js" />
      <Listrak />
      <Connexity />
      {isFeatureEnabled('isExtendWarrantyEnabled') && <ExtendWarranty />}
      <Component {...pageProps} />
      {isFeatureEnabled('isLoyaltyProgramEnabled') ? <ShopTokenScript /> : null}
    </RecoilRoot>

  )
}

const pathFromMaybeUrlMaybePath = (maybeUrlMaybePath: string): string => (
  maybeUrlMaybePath.includes('://')
    ? `/${(maybeUrlMaybePath.split('://')[1] || '').split('/').slice(1).join('/')}`
    : maybeUrlMaybePath
)

const sleepMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const isDirectHitOnCDNAuthority = (authority: string): boolean => (
  !!authority
  && `https://${authority}` !== SHOP_ORIGIN
  && `http://${authority}` !== SHOP_ORIGIN
)
const maybeRedirectDirectCDNAccessToRealWebsiteDomain = async (appContext: AppContext) => {
  const host = String(appContext.ctx.req?.headers?.host || appContext.ctx.req?.headers?.Host || '')
  const authority = String(
    appContext.ctx.req?.headers?.[':authority:']
    || appContext.ctx.req?.headers?.[':authority']
    || appContext.ctx.req?.headers?.authority
    || appContext.ctx.req?.headers?.[':Authority:']
    || appContext.ctx.req?.headers?.[':Authority']
    || appContext.ctx.req?.headers?.authority
    || appContext.ctx.req?.headers?.Authority
    || '',
  )
  if (host === PUBLIC_DNS_CDN_NAME || isDirectHitOnCDNAuthority(authority)) {
    appContext.ctx.res?.writeHead(301, { Location: `${SHOP_ORIGIN}${pathFromMaybeUrlMaybePath(String(appContext.ctx.req?.url))}` })
    appContext.ctx.res?.end()
    await sleepMs(1500)
    throw new Error('Redirecting to real website domain...')
  }
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const path = String(appContext.ctx.asPath || '')
  pushRequestId(appContext)
  appSSRFeatureTogglesSpy(appContext)
  await maybeRedirectDirectCDNAccessToRealWebsiteDomain(appContext)
  const pageProps = await App.getInitialProps(appContext)
  popRequestId()
  if (
    path.startsWith('/ssr-api')
    || path.includes('recommendation-iframe')
  ) {
    return {
      ...pageProps,
      returnAPIOnly: true,
    }
  }
  return {
    ...pageProps,
    returnAPIOnly: false,
  }
}

export default MyApp
